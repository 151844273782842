import React from 'react';
import {
    Nav,
    Navbar,
    NavDropdown,
} from 'react-bootstrap';
import styled from 'styled-components';
import { LinkContainer } from 'react-router-bootstrap';

import logo from '../../logo.svg';

const StyledImg = styled.img`
  width: 64px;
  height: 64px;
`

const NavigationBar = () => (
    <Navbar bg="light" expand="lg" >
        <Navbar.Brand>
            <LinkContainer to="/">
                <StyledImg src={logo} className="App-logo" alt="logo" />
            </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <LinkContainer exact to="/">
                    <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/about">
                    <Nav.Link>About</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/blogs">
                    <Nav.Link>Blogs</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to="/gallery">
                    <Nav.Link>Gallery</Nav.Link>
                </LinkContainer>
                <NavDropdown title="More" id="basic-nav-dropdown">
                    <NavDropdown.Item>
                        <LinkContainer exact to="/experience">
                            <Nav.Link>Experience</Nav.Link>
                        </LinkContainer>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <LinkContainer exact to="/notes">
                            <Nav.Link>Notes</Nav.Link>
                        </LinkContainer>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <LinkContainer exact to="/contact">
                            <Nav.Link>Contact</Nav.Link>
                        </LinkContainer>
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
)

export default NavigationBar;