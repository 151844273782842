/* eslint-disable */
import React from 'react';

const AngularJsNotes = () => (
    <div>
        <pre>{`
            Notes and Questions on Angular JS Framework
            http://www.guru99.com/angularjs-first-program.html
            
             
            
            <!doctype html>
            
            <html ng-app="demoApp">
            
              <head>
            
                 <script src="https://code.angularjs.org/1.4.0/angular.js"></script>
            
              </head>
            
              <body>
            
                 <h1 ng-controller="headingController">{{heading}}</h1>
            
                 <script>
            
                    var app = angular.module(' demoApp');
            
                    app.controller('headingController', function($scope) {
            
                       $scope.heading = 'Keep coding borther';
            
                    });
            
                </script>
            
              </body>
            
            </html>
            
             
            
            1.   The $scope object is used to manage the data between the controller and the view.
            
            2.    How to call a method on scope from expression?
            $scope.fun1 = function() {
               return 'hello';
            });
            
            {{fun1}} // does it works ?
            
            3.    app.config('$routeProvider', function($routeProvider) {
            $routeProvider.when('/about', {
            templateUrl: 'about.html',
            controller: 'aboutCtrl'
            }).when('/contact', {
            }).otherwise({
            reidirectTo:'/home'
            });
            
            4.   Difference between expression and $eval.
            expressions are used for evaluation in the view, the $eval is used in the controller function
            app.controller('myCtrl', function(){
                         $scope.a = 1; $scope.b = 1;
                        $scope.sum = $scope.$eval('a+b'); //$eval
            });
            <span>{{ sum}}</span> // Expression
            
            5.   {{fullName | lowercase}} // filter
            {{ 3.1466666 | number:2}} // 2 decimals i.e 3.15
            {{ 20.56 | currency }} // output $20.56.
            currency which is applied depends on the language settings which are applied on the machine
            
            6.   Filters are used to change the way the output is displayed to the user. Works like formatters.
            
            7.    app.filter('customFilter', function(input){
               return input+ 'something';
            });
            {{ 'myText' | customFilter}} // prints myTextsomething
            
            8.    app.directive('ngCustomDirctive', function(){
             return { template: '<span> from custom directive </span>'}
            });
            <div ng-customDirective=""></div>
            doubt: here template span appends to div or replaces div?
            
            9.    doubt: in nested controllers , can we access top controller scope variable in inner controller?
            
            10. app.directive('ngCustDir', function(){
            return {
                         controller: 'myController1',
                         controllerAs: 'myCtrl1',
                         template: '<span>{{myCtrl.age}}</span>'
            }
            });  // Define wt controller to be used for the custom directive.
            <div ng-custDir=""></div>
            
            11. reusable directive are like react ui-components
            
            12. web component are nothing but new custom html elements. i.e custom directives
            
            13. doubt: how ng-transclude working? wt it is doing exactly?
            
            14. doubt: what is transclude: true in custom directive?
            
            15. doubt: what is E in restrict : 'E' of inner directive? E is 'element'
            
            16. doubt: how to register click events?
            
            17. doubt: how to create multiple modules in one html file ?
            
            18. app.controller('myCtrl', function ($scope, $routeParams, $route) {
                $routeParams.customerId;// prints 342 bcz in route list.html#342
                $route.current.someText;//
            });
            
            
            
            
            app.config(['$routeProvider', function($routeProvider) {
              $routeProvider.when('/list/:customerId', {
                someText: 'some nonsense',
            templateUrl: 'list.html',
            controller: 'myCtrl'
              }
            });
            
            19. how to remove hash from sample.html#/angular/3 ?
            by using $locationProvider
            set $locationProvide.html5Mode(true); after routerProvider.when
            
            20. Dependency injectors
            angular.value('abc', 'alphabets');
            app.controller('myCtrl', function($scope, abc) {
            //here abc has been injected as value key injection method
            });
            
            using services
            angular.service('myService', function($scope){
              $scope.someFun = function(){
                  console.log('hello');
              }
            });
            app.controller('myCtrl', function($scope, myService) {
            //here myService has been injected as service injection method
            });
            
            21. karma is testing framework developed by same AngularJS team to test controllers and directives
            
            22. jasmine is reporting tool
            
            23. protractor is to help in identifying Angular custom html elements like ng-repeat etc 
            which can't be done by using web drivers framework.
            
            24. webdrive is to control the browser
            
            25. selenium web driver or junit is to capture web elements
            
            26. ng-include is used to fetch, compile and include an external HTML fragment
            
            27. include template using jquery $("#Content").load("Sub.html");
            Sub.html to be injected in the div tag which has the id of Content.
            
            28. <tr ng-repeat="topic in tutorial | orderBy: "name"></tr> // orderBy is filter
            <td> {{topic.name | uppercase}} </td>
            </tr>
            
            29. $index can be used in ng-repeat / ng-option
            
            30. $http.get("http://example/angular/getTopics.PHP").then(function(response) {})
            query([params], [success], [error]) - This can be used to make the standard GET call,
             but an array is returned as part of the response.
            
            31. save([params], postData, [success], [error]) - This can be used to make the standard POST call.
            
            32. $resource vs $http service
            
            33.  
            
            34. angular.module('DemoApp.services').factory('Entry', function($resource)
            
            35. {
            
            36.      return $resource('/example/Event/:1); // Note the full endpoint address
            
            37. });
            
            38.  
        `}</pre>
    </div>
)

export default AngularJsNotes
