import React from 'react';
import { FaGithub, FaLinkedin, FaMedium, FaStackOverflow, FaX, FaYoutube } from 'react-icons/fa6';
import styled from 'styled-components';

const StyledDiv = styled.div`
    align-self: center;
    align-items: center;
    margin: 15px;
`

const Container = styled.div`
    display: flex;
    flex-flow: column;
    `

const HeroImage = styled.div`
    width: 100%;
    height: 10px;
    background-color: #1fc8db;
    background-image: linear-gradient(140deg, #20ac07 0%, #FCFFAF 50%, #dc9a28 75%);
    color: white;
    opacity: 0.95;

    margin-bottom: 16px;
`
const StyledLi = styled.li`
    color:rgb(17, 57, 102);
    font-size: 18px;
    `
const StyledH3 = styled.h3`
    color:rgb(146, 87, 4);
    margin-top: 10px;
    margin-bottom: 10px;
`

const StyledP = styled.p`
    color:rgb(17, 57, 102);
    font-size: 18px;
    `
const StyledImg = styled.img`
    width: 320px;
    height: 320px;    
    align-self: center;
    align-items: center;
    margin: 15px;
    border-radius: 10%;
    border: 2px solid green;
`

const SocialMediaLinksDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 8px;  
    margin-bottom: 8px;
`
const SocialMediaLinksAnchro = styled.a`
    margin: 8px;
`

const SocialMediaLinks = () => {
    return (
        <SocialMediaLinksDiv>
            <SocialMediaLinksAnchro href="https://www.linkedin.com/in/uday-sravan-kamineni-65135321" target="_blank" >
                <FaLinkedin color="#0e76a8" size={28}/>
            </SocialMediaLinksAnchro>
            <SocialMediaLinksAnchro  href="https://github.com/UdaySravanK" target="_blank" >
                <FaGithub color="#000000" size={28}/>
            </SocialMediaLinksAnchro>
            <SocialMediaLinksAnchro href="https://stackoverflow.com/users/1897654/uday-sravan-k" target="_blank" > 
                <FaStackOverflow color="#f48024" size={28}/>
            </SocialMediaLinksAnchro>
            <SocialMediaLinksAnchro href="https://medium.com/@UdaySravanK" target="_blank" > 
                <FaMedium color="#f48024" size={28}/>
            </SocialMediaLinksAnchro>
            <SocialMediaLinksAnchro href="https://www.youtube.com/channel/UCo_c5UHweF2nr-Gr8aHS5fw" target="_blank" >
                <FaYoutube color="#c4302b" size={28}/>
            </SocialMediaLinksAnchro>
            <SocialMediaLinksAnchro href="https://x.com/usk_kamineni" target="_blank" >
                <FaX color="#1DA1F2" size={28}/>
            </SocialMediaLinksAnchro>
        </SocialMediaLinksDiv>
    )
}

// const HomePageHighlightsContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     margin-top: 8px;  
//     margin-bottom: 8px;
// `

// const HomePageHighlightsCapsule = styled.div`
//     display: flex;
//     justify-content: center;
//     margin-top: 8px;  
//     margin-bottom: 8px;
// `
// const HomePageHighlights = () => {
//     return (
//         <HomePageHighlightsContainer>
//             <HomePageHighlightsCapsule>
//             getLinkPreview
//                 <embed src='https://www.youtube.com/watch?v=NUQqOgR_61E'/>
//             </HomePageHighlightsCapsule>
//         </HomePageHighlightsContainer>
//     )
// }
const portfolioPic = process.env.PUBLIC_URL + '/home_profile_pic.jpg' // "https://lh3.googleusercontent.com/pw/AP1GczPJRUMX665ZGnzxJK-joJv9L8E2KYgUE76W0C49ib3cl9KSVRNrnKiB5tDkbjENqs_THi2f27RYjv0TU_lUg0Rb3VfXo4NlTDywkbBDVEJd8NBzbFY"
const Home = () => (
    <Container>
        <HeroImage />
        <SocialMediaLinks />
        <StyledDiv>
            <a href={portfolioPic}>
              <StyledImg src={portfolioPic}  alt='A selfie of a person in blue t-shirt at the Stillwater Lift Bridge'/>
            </a>
        </StyledDiv>
        <StyledH3>Hello <span role='img' aria-label="Waveing hand emoji">👋🏻</span></StyledH3>
        
        <StyledP>
        My name is Uday, and welcome to my personal website! Here, you can learn more <a href="#about">about me</a> and explore my passions. 
        I use this space to share my learning experiences, photography, videos, and more. Stay tuned for exciting content!
        </StyledP>
        <StyledP>About myself in a few lines</StyledP>
        <ul>
            <StyledLi>
                <span role='img' aria-label="car emoji">🚗</span> I love to travel the world <span role='img' aria-label="globe emoji">🌎</span>
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="cartwheel emoji">🤸</span> Fitness enthusiast <span role='img' aria-label="biceps emoji">💪🏼</span>
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="laptop emoji">💻</span> Software developer since 2011 <span role='img' aria-label="working on laptop emoji">👨🏻‍💻</span>
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="ring emoji">💍</span> Married & father of two boys <span role='img' aria-label="children emoji">👦🏻👦🏻</span>
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="peach emoji">🍑</span> Currently living in Georgia, USA <span role='img' aria-label="usa flag emoji">🇺🇸</span>
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="movie emoji">📽️</span> I enjoy watching action and adventure movies. <span role='img' aria-label="spider web emoji">🕸️</span>
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="excited emoji">🤩</span> Passionate about the cutting edge revolutionary technologies <span role='img' aria-label="rocket emoji">🚀</span>
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="raised hands emoji">🙌🏻</span> Open source project author
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="video camera emoji">📹</span> Vlogger & Blogger
            </StyledLi>
            <StyledLi>
                <span role='img' aria-label="coffee emoji">☕️</span> Can't live without a coffee or tea
            </StyledLi>
        </ul>

        <StyledP> <span role='img' aria-label="heart emoji">🧡</span> Thanks for visiting my website! <span role='img' aria-label="cheers emoji">🥂</span></StyledP>
        
        {/* <HomePageHighlights /> */}
    </Container>
);

export default Home;