/* eslint-disable */
import React from 'react';

const Home = () => (
    <div>
        <h4>Android personal interview notes from year 2014</h4>
        <pre>{`
        Notes and Questions on Android Technologies
        Android
        
        Fragments
        
        Intent flags sticky intents /services stack_task_cleartop
        
        Broadcast flags
        
        Content Provider example
        
        PendingIntent with FLAG_UPDATE_CURRENT
        
        AsyncTask
        
        memory analyzer
        
        Managing cache files
        
        Application sandbox
        
        Inter Process Communication
        
        Android Interface definition language
        
        what is invalidate
        
        Android constraints
        
        OpenGLES 2.0
        
        Loaders
        
        SyncAdapters
        
        Intelligent Job Scheduling
        
        GDM
        
        Package Signing
        
        Google Instant Apps
                    Won't support push notifications, content provider, changing user settings like wallpaper
        
        Injecting frameworks like Dagger
        
         
        
         
        
        Questions
        
        1.     Activity is GUI which represents one screen.
        
        2.     Can we have two activities on one screen?
        
        3.     What is the diff bw simulator and emulator?
        
        4.     Explain 2Androidexceptions?
        
        5.     NewAndroidproject structure with ADT ? and manifest structure?
        
        6.     What is sticky intent?
        
        7.     How broadcast receiver works?
        
        8.     What are different flags you know to start services/activities/broadcast receivers?
        
        9.     What is the diff bw DVM & JVM?
        
        10.  How an app widget works? How to implement it?
        
        11.  What methods gets called while changing orientation?
        
        12.  What are the steps to publish and app to play store?
        
        13.  How Multiple manifest files works?
        
        14.  Sticky intent? Let's say fired and broadcast intent 
        but not one registered but still if someone registered in the future 
        they will receive broadcast msg. Bcz sticky intent sticked toAndroidOS.
        
        15.  Explain app caching techniques ?
        
        16.  How to add multiple sub project in ADT IDE?
        
        17.  Diff bw different activity classes? Activity.java / AppCompatActivity.java
        
        o   AppCompatActivity is base class for activities that use the 
        support library action bar features. Extends v4 FragmentActivity
        
        18.  Diff bw widgets? like EditText and AppCompatEditText
        
        19. How to write and run test cases? How to generate report? (http://d.android.com/tools/testing)
        20.  What is @RunWith, @Test
        
        21.  What do u know about junit?
        
        22.  import static org.junit.Assert.*   // why static here?
        
        23.  what is mipmap?
        
        24.  What isAndroidO ?
        
        25.  Whats the lang used in gradle scripts? groovy programming language
        
        26.  What do u know about maven?
        
        27.  What is jcenter? itsAndroidplugin/library repository
        
        28.  What is proguard? check http://developer.android.com/guide/developing/tools/proguard.html
        
        29.  what is com.android.support.test.espresso:espresso-core?
        
        30.  What is xml resource dimen ?
        
        31.  What is pixel density?
        
        32.  How to implementAndroidapp instructions?
        
        33.  What is firebase? https://firebase.google.com/docs/cloud-messaging/
        
        34.  How to stop device fall in sleep screen mode?
        
        35.  How to enable extended notifications messages?
        
        36.  How to make interactive notification messages? i.e reply from notifications
        
        37.  List of newly added features and UI components or apis like recycler? split screen etc ?
        
        38.  How to make app work in split screen mode?
        
        39.  What is LoaderManager?
        
        40.  How do you reuse list item views for large list with heavy view items?
        
        41.  What is navigateUpTo method does?           
        http://developer.android.com/design/patterns/navigation.html#up-vs-back
        
        42.  How to implement refresh list on pulling down like gmail?
        
        43.  How to implement caching of images in a shopping app? using file storage
        if we write some cache files to external storage then when user uninstalls the app, 
        the system removes app's files only if we saved them in the directory from getExternalFilesDir(). 
        use getCacheDir() of internal storage.
        File.createTempFile(fileName, null, context.getCacheDir())
        
        44.  What if we don't write code for activity chooser? systems shows chooser automatically.
        
        45.  use MODE_PRIVATE for your files on the internal storage, they are never accessible to other apps
        
        46.  preferences vs shared preference? one is for settings and other is to store key-values
        
        47.  write files to external storage using getExternalFilesDir() to get deleted on app uninstall. private files.
        
        48.  For external storage public files use getExternalStoragePublicDirectory()
        
        49.  String[] projection = {Phone.NUMBER}; // this syntax looks new in java!!
        
        50.  How to create custom intent actions and filters?
        
        51.  Example for IllegalArgumentException?
        
        52.  What is requestLayout()?
        
        53.  SurfaceView vs Canvas
        
        54.  Sync Adapters to sync data bw client and server.
        
        55.  Firebase Cloud Messaging is middle level module to stop polling servers directly from client.
        
        56.  Volley vs DownloadManager
        
        57.  Activities, Services, BroadcaseReceivers activated by intents. ContentProviders activated by content resolvers
        
        58.  expandable product description box.. show more link?
        
        59.  Image carousel with zoom features?
        
        60.  Animation of ATC product moving to cart?
        
        61.  Infinite vertical scroll of products.. load next page after reaching to bottom of page?
        
        62.  pull down refresh feature?
        
        63.  search hints drop down/ suggestions spinner ?
        
        64.  what is facade?
        
        65.  how to identify native installed or not from web app?
        
        66.  Card swipe to top right corner to remove or go to next image feature?
        
        67.  How payment gate way works
        
        68.  why recyclerview doesn't have on item click listener?
        
         
        
        NEW COMPONENTS
        
        Toolbar/ CollapsingToolbarLayout
        
        AppBarLayout/ NestedScrollView
        
        FloatingActionButton
        
        CoordinatorLayout
        
        ConstraintLayout
        
        FileProvider in v4 support lib. to use we need to set <provider> in manifest
        
         
        
         
        
         
        
         
        
         
        
        Notes
        
        When to use this & getapplicationcontext:
        
        1.If the control or variable you are creating should belong to application level then use applicationContext.
        
        2.If the control or variable you are creating should belong to Activity level then use this pointer or this context.
        
         
        
        When programmer wants to create any component or control, then you have to use one of the contexts.
        
        eg: TextView t = new TextView(this);
        
        Here we are using this pointer (context).
        
        eg: static TextView st = new TextView(getApplicationContext());
        
        Here we are using application context, because it is a static variable whose life time will be through out application life time.
        
        If you use this pointer here, then it will leak memory (memory wastage) of your activity.
        
        1.     ConstraintLayout helps to create flat view hieararchy. That is, it avoids the need for nested layouts.
        
        2.     In Studio 2.3 build cached introduced for gradle build tool. 
        Which compileds and keeps libraries in a common shared folder so other apps can use it. 
        This is for build performance boost.
        
        3.     support library allows you to use several recent platform APIs on older versions
        
        4.     To make your activity look like a dialog box:
        <activity android:theme="@android:style/Theme.Dialog">
        
        5.     Stock Android = pureAndroid= vanillaAndroid= A version/instance of 
        Android which hasn't been mangled by third-party skins, apps and "mods" in general. Also known as "vanilla Android"
        
        6.     Doze saved your batter in the night while in u r in sleep
        
        7.     Android Go will basically be a lightweight version of the upcoming Android O,
         but designed to run on smartphones that have 1 GB, or even 512 MB,  of RAM. Like Android One phones.
        
        8.     Fragments introduced in api 11
        - are reusable, modular UI components
        - maintains it's own life cycle in sync with parent activity
        - works like nested activities/ sub activities
        - A Fragment class must associate with FragmentActivity class
        - if fragment added from xml then can not be removed at run time
        - use FragmentManager to create a FragmentTransaction like add/remove/replace etc 
        in Activity's onCreate method into some container like FrameLayout/ RelativeLayout etc.
        - transaction.addToBackStack(null); // do it after remove/replace so user can navigate to previous 
        removed fragment then perform commit(); instead null pass a name for advanced transactions on back stack
        - two fragments never communicates directly. should be by using associated activity
        - Fragment A calls callback interface of activity then activity call FragmentB method.
        
        9.     Internal storage is best when you want to be sure that neither the user nor other apps can access your files.
        
        10.   External storage is the best place for files that don't require access restrictions 
        and for files that you want to share with other apps or allow the user to access with a computer.
        
        11.   In File sharing (gallary shares pic file with photo editors) Content URIs with 
        temporary URI access permissions are secure because they apply only to the app that receives the URI, and they expire automatically.
        
        12.   ActionProvider/ ShareActionProvider to enable share icon in ActionBar menu
        
        13.   ContentURI to share a file will allow client app to read the file even if client app 
        doesn't have read exteranal storage permission. Like gmail app.
        
        14.   FileSharing securely: client(gmail) app sends a request with intent to select a file 
        from server(kind of gallery) app. Server app sends the ContentURI of file instead of direct 
        file path in returnIntent to client app with temporary read access. By using content resolver client app reads the file.
        
        15.   Loaders are like adapter+async task kind of . Monitors data change too.
        
        16.   Java reflectors are used to inspect a class implementation in the run time. Like 
        to check what methods a class has , what members and what type like private/public etc. using Class.forName() instance.
        
        17.   use onStop() to release resources that might leak memory, because it is possible 
        for the system to kill the process hosting your activity without calling the activity's final onDestroy() callback
        
        18.   use isFinishing() method to distinguish bw app destorying due to finish() method or 
        system destroying it to free memory when app is in onStop state in the background.
        
        19.   onSaveInstanceState won't get called if user clicked on back button.
        
        20.   java compiled and statically typed; java script interpreted and dynamically typed
        
        21.   annotations are for compilers.
        
        22.   Hiding internal data from the outside world, and accessing it only through publicly 
        exposed methods is known as data encapsulation.
        
        23.   In java declaring array is as int[] array = {3, 5, 54}; multidimension is 
        int[][] arr = {{3,4,5}, {6,7,8}, {1,2,3}}
        
        24.   in java null is not an instance of anything
        
        25.   int[][] a = new int[x][y];
        
        26.   parentheses, () ; brackets {}; square brackets []
        
        27.   passing an object to match will be sent as .. pass by value. So the reference 
        passed in by value can not change the actual passed object but can change member values of that object
        public class Test {
            public static void main(String[] args) {
            Circle c1 = new Circle(4, 5);
            doSomething(c1);
            }

            private static void doSomething(Circle c){
            c.x = 7;c.y =8;
            c = new Circle(9,9);
            }

            private static class Circle {
            private int x, y;
            public Circle(int x, int y) {
            this.x = x;
            this.y = y;
            }
            }
        }
        
        28.   public, private, protected and package-private (default access modifier).
        
        29.   The protected modifier specifies that the member can only be accessed within 
        its own package (as with package-private) and, in addition, by a subclass of its class in another package.
        package pack1;
        class1 {
        protected int protectedA = 5;
        int defaultB = 30;
        }
        package pack2;
        class 2 extends class1{
        this. protectedA ;// can access
        this. defaultB ; // cannot access
        }
        
        30.   keyword static: static fields or class variables are associated with class instead 
        of an object created for that class. Every instance of the class shares a class variable, 
        which is in one fixed location in memory. Any object can change the value of a class variable.
        
        31.   instance variables
        
        32.   cannot override final methods
        
        33.   an outer class can only be public or package-private (means no modifier)
        
        34.   local class: a class inside the body of a method
        
        35.   A variable or parameter whose value is never changed after it is initialized is effectively final
        
        36.   captured variable :a final variable in a method accessed by its local class.
        
        37.   in regualr expressions '[]' means all . ex [^0-9] all not zero to nine characters
        
        38.   local classes cannot use interface implement mechanism. bcz interfaces are inherently static.
        
        39.   local classes cannot have static member or  methods
        
        40.   Anonymous class: We can't reuse the class implementation. Because it doesn't have any name
        show(new A(){
            public void method1(){
            }
            // you can't use this class A implementation to create another instance object for A
        });
        
        
        41.   default keyword in java 8 useful to write default implementation for a interface method in interface file it self.
        
        42.   there is only call by value in java
        
         
        
        Lollipop features
        
        1.     Material design - Small animations in notification icons like orientation change, more screen colors with sharp layouts
        
        2.     recent apps after reboot
        
        3.     DND mode
        
        4.     inbuild chromecast
        
        5.     Guest mode to share device with others easily
        
        6.     search settings
        
        7.     pin apps for kids - restrct to move to other apps.
        
        8.     Interact with notifications from lock screen
        
        9.     app notification enable /disable app level
        
        10.  flash light in notifications
        
        Marshmallow features SDK 23
        
        1.     Long-pressing the home button now activates Google Now on Tap
        
        2.     Fingerprint hard ware support and Fingerprint API
        
        3.     Doze : intelligent battery management feature that recognizes when your device is not is use, 
        like when it has been lying on a bedside table for a while, and enters hibernation
        
        4.     Type-C and reverse charging. Means you can charge one mobile from other mobile
        
        5.     Permissions for a particular app particular permission can be viewed and changed
        Request permission at run time
        
        6.     Automatic app backup
        
        7.     Chrome custom tabs. A Chrome-based in-app mini-browser that developers can use to display 
        web page content within their app (like an FAQ or Help page), rather than having a user bounce from their app to a web app
        
        8.     Delete screenshots from notifications shade, Silent mode/priority notifications, Network security reset
        
        9.     solved problem of repeated tell the system to always open certain app links with a certain app
        
        Naugat features
        
        1.     close all button for recent apps
        
        2.     bundle / group multiple notifications
        
        3.     double tap on multitasking button to quickly swap bw two recent apps
        
        4.     Multi window
        
        5.     GIF file emoji keyboard
        
        6.     Google assistant
        
        Android O
        
        1.     picture-in-picture
        
        2.     Notification Dots
        
        3.     notification icon on top of an app icon on your Homescreen
        
        4.     Smart text selection - with one tap after text selection , can go to address on maps or can call to a number etc
        
        Google I/O keynote 2017
        
        1.     Google lens
        
        2.     Youtube 360 video on TV with tv remote control
        
        3.     kotlin
        
        4.      
        
         
        
        URLS
        
        square.github.io/picasso/ for gradle compile library package
        
        https://bintray.com/android/android-utils/com.android.volley.volley/view
        
        ForAndroidnews
        
        https://www.androidpit.com/
        
        http://www.androidpolice.com/
        
        http://www.androidauthority.com/
        
        https://www.javatpoint.com/corejava-interview-questions
        
        https://willowtreeapps.com/ideas/an-introduction-to-android-instant-apps
        
        https://github.com/googlesamples/android-architecture
        
        https://developer.android.com/topic/libraries/support-library/features.html
        
         
        
        Libs
        
        Retrofit - REST api client like volley
        
        Fresco - image loading and caching like picasso
        `}</pre>
    </div>
);

export default Home;